.main-social-2-container-light {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 500px;

    .main-social-2 {
        display: flex;
        justify-content: space-between;
        width: 500px;
        margin-left: -80px;

        .RColor {
            -webkit-filter: invert(0%);
            filter: invert(0%);
        }

        .img {
            width: 55px;
            height: max-content;
            border: none;
            border-radius: 10px;
            background: transparent;

            img {
                width: 100%;
            }
        }

        .light-icon {
            color: black !important;
        }

        .leetcode {
            width: 75%;
            margin-top: 6px;
        }

        .tooltip-container {
            position: relative;
            cursor: pointer;
            transition: all 0.2s;
            font-size: 17px;
            border-radius: 25px;
            width: 30px;
            margin-left: 20px;
        }

        .tooltip {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            padding: 5px;
            opacity: 0;
            pointer-events: none;
            transition: all 0.3s;
            border-radius: 15px;
            box-shadow: inset 5px 5px 5px rgb(108, 108, 108),
                inset -5px -5px 15px rgb(193, 193, 193, 0.1),
                5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
        }

        .profile {
            background: rgb(163, 163, 163);
            border-radius: 10px 15px;
            padding: 10px;
            border: 1px solid #616161;
            width: max-content;
            z-index: 99999;
        }

        .tooltip-container:hover .tooltip {
            top: -130px;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }

        .icon {
            text-decoration: none;
            color: #000;

            display: block;
            position: sticky;
        }

        .layer {
            width: 55px;
            height: 55px;
            transition: transform 0.3s;
        }

        .icon:hover .layer {
            transform: rotate(-35deg) skew(20deg);
        }

        .layer span {
            position: absolute;
            top: 0;
            left: 0;
            height: 90%;
            width: 50px;
            border: 1px solid #000;
            border-radius: 50px;
            transition: all 0.3s;
        }

        .layer span,
        .text {
            color: #000;
            border-color: #000;
        }

        .icon:hover.layer span {
            box-shadow: -1px 1px 3px #000;
        }

        .icon .text {
            position: absolute;
            left: 50%;
            bottom: -5px;
            opacity: 0;
            font-weight: 500;
            transform: translateX(-50%);
            transition: bottom 0.3s ease, opacity 0.3s ease;
        }

        .icon:hover .text {
            bottom: -35px;
            opacity: 1;
        }

        .icon:hover .layer span:nth-child(1) {
            opacity: 0.2;
        }

        .icon:hover .layer span:nth-child(2) {
            opacity: 0.4;
            transform: translate(5px, -5px);
        }

        .icon:hover .layer span:nth-child(3) {
            opacity: 0.6;
            transform: translate(10px, -10px);
        }

        .icon:hover .layer span:nth-child(4) {
            opacity: 0.8;
            transform: translate(15px, -15px);
        }

        .icon:hover .layer span:nth-child(5) {
            opacity: 1;
            transform: translate(20px, -20px);
        }

        .layer span.fab {
            font-size: 25px;
            line-height: 55px;
            text-align: center;
            fill: #000;
            background: rgb(163, 163, 163);
        }

        .layer span.fab1 {
            font-size: 30px;
            line-height: 55px;
            text-align: center;
            fill: #fff;
            background: rgb(163, 163, 163);
        }

        .user {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .name {
            font-size: 17px;
            font-weight: 700;
            color: #000;
        }

        .username {
            font-size: 15px;
        }

        .details {
            display: flex;
            flex-direction: column;
            gap: 0;
            color: #000;
            justify-content: space-around;
        }

        .about {
            color: #ddd;
            padding-top: 5px;
        }

        .card00 {
            width: 290px;
            height: 352px;
            background-image: linear-gradient(163deg, #506475 0%, black 100%);
            border-radius: 20px;
            transition: all .3s;
        }

        .card21 {
            width: 290px;
            height: 30px;
            background-color: #000000;
            border-radius: 20px;
            transition: all .2s;
        }

        .card2:hover {
            transform: scale(0.98);
            border-radius: 20px;
        }

        .card:hover {
            box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.30);
        }

    }

}

.wholeSkills{
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    height: 590px;
    margin-top: -90px;
    padding-top: 100px;
}

.wholeSkills::-webkit-scrollbar{
    opacity: 0;
}

.stats{
    margin-left: 34vw;
    display: flex;
    flex-direction: column;
    width: 500px;
}

.main-social-2-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 500px;

    .main-social-2 {
        display: flex;
        justify-content: space-between;
        width: 500px;
        margin-left: -80px;

        .RColor {
            -webkit-filter: invert(100%);
            filter: invert(100%);
        }

        .img {
            width: 55px;
            height: max-content;
            border: none;
            border-radius: 10px;
            background: transparent;

            img {
                width: 100%;
            }
        }

        .leetcode {
            width: 75%;
            margin-top: 6px;
        }

        .tooltip-container {
            position: relative;
            cursor: pointer;
            transition: all 0.2s;
            font-size: 17px;
            border-radius: 25px;
            width: 30px;
            margin-left: 20px;
        }

        .tooltip {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            padding: 5px;
            opacity: 0;
            pointer-events: none;
            transition: all 0.3s;
            border-radius: 15px;
            box-shadow: inset 5px 5px 5px black,
                inset -5px -5px 15px rgb(193, 193, 193, 0.1),
                5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
        }

        .profile {
            background: #2a2b2f;
            border-radius: 10px 15px;
            padding: 10px;
            border: 1px solid #000;
            width: max-content;
            z-index: 99999;
        }

        .tooltip-container:hover .tooltip {
            top: -130px;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }

        .icon {
            text-decoration: none;
            color: #fff;

            display: block;
            position: sticky;
        }

        .layer {
            width: 55px;
            height: 55px;
            transition: transform 0.3s;
        }

        .icon:hover .layer {
            transform: rotate(-35deg) skew(20deg);
        }

        .layer span {
            position: absolute;
            top: 0;
            left: 0;
            height: 90%;
            width: 50px;
            border: 1px solid #fff;
            border-radius: 50px;
            transition: all 0.3s;
        }

        .layer span,
        .text {
            color: #fff;
            border-color: #fff;
        }

        .icon:hover.layer span {
            box-shadow: -1px 1px 3px #fff;
        }

        .icon .text {
            position: absolute;
            left: 50%;
            bottom: -5px;
            opacity: 0;
            font-weight: 500;
            transform: translateX(-50%);
            transition: bottom 0.3s ease, opacity 0.3s ease;
        }

        .icon:hover .text {
            bottom: -35px;
            opacity: 1;
        }

        .icon:hover .layer span:nth-child(1) {
            opacity: 0.2;
        }

        .icon:hover .layer span:nth-child(2) {
            opacity: 0.4;
            transform: translate(5px, -5px);
        }

        .icon:hover .layer span:nth-child(3) {
            opacity: 0.6;
            transform: translate(10px, -10px);
        }

        .icon:hover .layer span:nth-child(4) {
            opacity: 0.8;
            transform: translate(15px, -15px);
        }

        .icon:hover .layer span:nth-child(5) {
            opacity: 1;
            transform: translate(20px, -20px);
        }

        .layer span.fab {
            font-size: 25px;
            line-height: 55px;
            text-align: center;
            fill: #fff;
            background: #212121;
        }

        .layer span.fab1 {
            font-size: 30px;
            line-height: 55px;
            text-align: center;
            fill: #fff;
            background: #212121;
        }

        .user {
            display: flex;
            gap: 10px;
            align-items: center;
        }

        .name {
            font-size: 17px;
            font-weight: 700;
            color: #fff;
        }

        .username {
            font-size: 15px;
        }

        .details {
            display: flex;
            flex-direction: column;
            gap: 0;
            color: #fff;
            justify-content: space-around;
        }

        .about {
            color: #ccc;
            padding-top: 5px;
        }

        .card00 {
            width: 290px;
            height: 352px;
            background-image: linear-gradient(163deg, #506475 0%, black 100%);
            border-radius: 20px;
            transition: all .3s;
        }

        .card21 {
            width: 290px;
            height: 30px;
            background-color: #000000;
            border-radius: 20px;
            transition: all .2s;
        }

        .card2:hover {
            transform: scale(0.98);
            border-radius: 20px;
        }

        .card:hover {
            box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.30);
        }

    }
}