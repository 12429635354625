.section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 92vh;

  .logo-container {
    position: relative;
    width: 400px;
    height: 400px;
    background-color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 50%;
    margin-left: 50vw;
    overflow: hidden;
  }

  .logo-container::before {
    content: '';
    position: absolute;
    inset: -10px 140px;
    background: linear-gradient(315deg, #D9722B, #F9BC47) !important;
    transition: 0.5s;
    animation: animate 4s linear infinite;
  }

  .logo-container:hover::before {
    inset: -20px 0px;
  }

  .logo-container::after {
    content: '';
    position: absolute;
    inset: 6px;
    background: #4f4f4f;
    border-radius: 50%;
    z-index: 1;
  }

  .content {
    position: absolute;
    inset: 30px;
    border: 6px solid #070a1c !important;
    z-index: 3;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .content img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s;
    pointer-events: none;
    z-index: 3;
  }

  .logo-container:hover .content img {
    opacity: 0;
  }

  .content h2 {
    position: relative;
    font-size: 1.5em !important;
    text-align: center;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-decoration: uppercase;
  }

  .content h2 span {
    font-weight: 300;
    font-size: 0.75em !important;
  }

  .content .a {
    position: relative;
    margin-top: 10px;
    padding: 10px 20px;
    background: #fff;
    color: #070a1c;
    border-radius: 25px;
    font-weight: 500;
    font-size: 1.25em !important;
    text-transform: uppercase;
    letter-spacing: 0.05em !important;
    text-decoration: none;
    transition: 0.5s;
  }

  .content .a:hover {
    letter-spacing: 0.2em !important;
  }

  .content .a:active {
    opacity: 0.7;;
  }

}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  0% {
    transform: rotate(360deg);
  }
}