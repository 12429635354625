.Project-div {
    margin-top: -35px;
    margin-left: 80px;
}

.Project-div-2 {
    height: 440px;
    margin-left: 20px;
    overflow: auto;
    padding-top: 20px;
    padding-bottom: 100px;
}


.Project-div-2::-webkit-scrollbar {
    width: 0 !important;
}

.Divider {
    background: white !important;
    width: 1.5px !important;
}

.Divider-light {
    background: black !important;
    width: 1.5px !important;
}

.Togglebutton {
    padding: 8px 18px !important;
    border-radius: 6px !important;

    &:hover {
        background: rgba(255, 255, 255, 0.118) !important;
    }
}

.Togglebutton2 {
    padding: 8px 18px !important;
    background: rgba(255, 255, 255, 0.193) !important;
    border-radius: 6px !important;
}

.TogglebuttonLight {
    padding: 8px 18px !important;
    border-radius: 6px !important;

    &:hover {
        background: rgba(0, 0, 0, 0.215) !important;
    }
}

.Togglebutton2Light {
    padding: 8px 18px !important;
    background: rgba(0, 0, 0, 0.393) !important;
    border-radius: 6px !important;
}