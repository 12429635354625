.exp-page {
  .h1 {
    font-size: 53px !important;
    font-family: 'Coolvetica';
    color: #d47331;
    font-weight: 400;
    margin-top: 50px;
    position: relative;
    margin-bottom: 40px;
    left: -10px;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #d47331;
      font-size: 18px;
      position: absolute;
      margin-top: 10px;
      left: 472px;
      opacity: 0.6;
      line-height: 18px;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #d47331;
      font-size: 18px;
      line-height: 18px;
      position: absolute;
      left: 445px;
      bottom: -5px;
      margin-left: 20px;
      opacity: 0.6;
    }
  }

  p {
    margin-top: -20px;
    text-align: center;
    margin-left: 460px;
    font-size: 18px;
    font-family: sans-serif;
    font-weight: 300;
    max-width: 550px;
    animation: pulse 1s;

    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }

    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }

  .h11 {
    font-size: 53px !important;
    font-family: 'Coolvetica';
    color: #d47331;
    font-weight: 400;
    margin-top: 50px;
    position: relative;
    margin-bottom: 40px;
    left: -10px;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #d47331;
      font-size: 18px;
      position: absolute;
      margin-top: 10px;
      left: 550px;
      opacity: 0.6;
      line-height: 18px;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #d47331;
      font-size: 18px;
      line-height: 18px;
      position: absolute;
      left: 527px;
      bottom: -5px;
      margin-left: 20px;
      opacity: 0.6;
    }
  }

  .h12 {
    font-size: 53px !important;
    font-family: 'Coolvetica';
    color: #d47331;
    font-weight: 400;
    margin-top: 50px;
    position: relative;
    margin-bottom: 40px;
    left: -10px;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #d47331;
      font-size: 18px;
      position: absolute;
      margin-top: 10px;
      left: 515px;
      opacity: 0.6;
      line-height: 18px;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #d47331;
      font-size: 18px;
      line-height: 18px;
      position: absolute;
      left: 490px;
      bottom: -5px;
      margin-left: 20px;
      opacity: 0.6;
    }
  }

  .h13 {
    font-size: 53px !important;
    font-family: 'Coolvetica';
    color: #d47331;
    font-weight: 400;
    margin-top: 50px;
    position: relative;
    margin-bottom: 40px;
    left: -10px;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #d47331;
      font-size: 18px;
      position: absolute;
      margin-top: 10px;
      left: 490px;
      opacity: 0.6;
      line-height: 18px;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #d47331;
      font-size: 18px;
      line-height: 18px;
      position: absolute;
      left: 465px;
      bottom: -5px;
      margin-left: 20px;
      opacity: 0.6;
    }
  }
}