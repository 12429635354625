html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font: 300 11px/1.4 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  display: block;
  cursor: url('assets/images/cursor2.png'), pointer;
}

.dark-theme {
  background: #212121;
  color: #fff;

  .Togglebutton2 {
    padding: 8px 18px !important;
    background: rgba(255, 255, 255, 0.193) !important;
    border-radius: 6px !important;
  }

  .Togglebutton {
    padding: 8px 18px !important;
    border-radius: 6px !important;

    &:hover {
      background: rgba(255, 255, 255, 0.118) !important;
    }
  }

  .Divider {
    background: white !important;
    width: 1.5px !important;
  }

  .mainsocialC {
    margin-top: -40px;
    z-index: 99999;
    display: flex;

    .img-custom {
      width: 55px;
      height: 55px;
      border: 1px solid #ff9100;
      border-radius: 10px;
      background: #fff;

      img {
        width: 100%;
      }
    }

    .RColor {
      -webkit-filter: invert(0%);
      filter: invert(0%);
    }

    .leetcode {
      width: 75%;
      margin-top: 6px;
    }

    .tooltip-container-custom {
      position: relative;
      cursor: pointer;
      transition: all 0.2s;
      font-size: 17px;
      border-radius: 25px;
      width: 35px;
      margin-left: 20px;
    }

    .tooltip-custom {
      margin-left: 40px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 5px;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s;
      border-radius: 15px;
      box-shadow: inset 5px 5px 5px #ff9100,
        inset -5px -5px 15px rgba(255, 255, 255, 0.1),
        5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
    }

    .profile-custom {
      background: #2a2b2f;
      border-radius: 10px 15px;
      padding: 10px;
      border: 1px solid #ff9100;
      width: max-content;
      z-index: 99999;
    }

    .tooltip-container-custom:hover .tooltip-custom {
      top: -150px;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }

    .icon-custom {
      text-decoration: none;
      color: #fff;

      display: block;
      position: sticky;
    }

    .layer-custom {
      width: 55px;
      height: 55px;
      transition: transform 0.3s;
    }

    .icon-custom:hover .layer-custom {
      transform: rotate(-35deg) skew(20deg);
    }

    .layer-custom span {
      position: absolute;
      top: 0;
      left: 0;
      height: 65%;
      width: 35px;
      border: 1px solid #fff;
      border-radius: 50px;
      transition: all 0.3s;
    }

    .layer-custom span,
    .text-custom {
      color: #fff;
      border-color: #fff;
    }

    .icon-custom:hover.layer-custom span {
      box-shadow: -1px 1px 3px #fff;
    }

    .icon-custom .text-custom {
      position: absolute;
      left: 50%;
      bottom: -5px;
      opacity: 0;
      font-weight: 500;
      transform: translateX(-50%);
      transition: bottom 0.3s ease, opacity 0.3s ease;
    }

    .icon-custom:hover .text-custom {
      bottom: -20px;
      opacity: 1;
    }

    .icon-custom:hover .layer-custom span:nth-child(1) {
      opacity: 0.2;
    }

    .icon-custom:hover .layer-custom span:nth-child(2) {
      opacity: 0.4;
      transform: translate(5px, -5px);
    }

    .icon-custom:hover .layer-custom span:nth-child(3) {
      opacity: 0.6;
      transform: translate(10px, -10px);
    }

    .icon-custom:hover .layer-custom span:nth-child(4) {
      opacity: 0.8;
      transform: translate(15px, -15px);
    }

    .icon-custom:hover .layer-custom span:nth-child(5) {
      opacity: 1;
      transform: translate(20px, -20px);
    }

    .layer-custom span.fab {
      font-size: 18px;
      line-height: 40px;
      text-align: center;
      fill: #fff;
      background: #212121;
    }

    .layer-custom span.fab1 {
      font-size: 18px;
      line-height: 40px;
      text-align: center;
      fill: #fff;
      background: #212121;
    }

    .user-custom {
      display: flex;
      gap: 10px;
    }

    .name-custom {
      font-size: 17px;
      font-weight: 700;
      color: #fff;
    }

    .details-custom {
      display: flex;
      flex-direction: column;
      gap: 0;
      color: #fff;
    }

    .about-custom {
      color: #ccc;
      padding-top: 5px;
    }

    .card00-custom {
      width: 290px;
      height: 352px;
      background-image: linear-gradient(163deg, #506475 0%, #ff9100 100%);
      border-radius: 20px;
      transition: all .3s;
    }

    .card21-custom {
      width: 290px;
      height: 30px;
      background-color: #000000;
      border-radius: 20px;
      transition: all .2s;
    }

    .card2-custom:hover {
      transform: scale(0.98);
      border-radius: 20px;
    }

    .card-custom:hover {
      box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.30);
    }
  }

  .main-social-2-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 500px;

    .main-social-2 {
      display: flex;
      justify-content: space-between;
      width: 500px;
      margin-left: -80px;

      .RColor {
        -webkit-filter: invert(100%);
        filter: invert(100%);
      }

      .img {
        width: 55px;
        height: max-content;
        border: none;
        border-radius: 10px;
        background: transparent;

        img {
          width: 100%;
        }
      }

      .leetcode {
        width: 75%;
        margin-top: 6px;
      }

      .tooltip-container {
        position: relative;
        cursor: pointer;
        transition: all 0.2s;
        font-size: 17px;
        border-radius: 25px;
        width: 30px;
        margin-left: 20px;
      }

      .tooltip {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 5px;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s;
        border-radius: 15px;
        box-shadow: inset 5px 5px 5px black,
          inset -5px -5px 15px rgb(193, 193, 193, 0.1),
          5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
      }

      .profile {
        background: #2a2b2f;
        border-radius: 10px 15px;
        padding: 10px;
        border: 1px solid #000;
        width: max-content;
        z-index: 99999;
      }

      .tooltip-container:hover .tooltip {
        top: -130px;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }

      .icon {
        text-decoration: none;
        color: #fff;

        display: block;
        position: sticky;
      }

      .layer {
        width: 55px;
        height: 55px;
        transition: transform 0.3s;
      }

      .icon:hover .layer {
        transform: rotate(-35deg) skew(20deg);
      }

      .layer span {
        position: absolute;
        top: 0;
        left: 0;
        height: 90%;
        width: 50px;
        border: 1px solid #fff;
        border-radius: 50px;
        transition: all 0.3s;
      }

      .layer span,
      .text {
        color: #fff;
        border-color: #fff;
      }

      .icon:hover.layer span {
        box-shadow: -1px 1px 3px #fff;
      }

      .icon .text {
        position: absolute;
        left: 50%;
        bottom: -5px;
        opacity: 0;
        font-weight: 500;
        transform: translateX(-50%);
        transition: bottom 0.3s ease, opacity 0.3s ease;
      }

      .icon:hover .text {
        bottom: -35px;
        opacity: 1;
      }

      .icon:hover .layer span:nth-child(1) {
        opacity: 0.2;
      }

      .icon:hover .layer span:nth-child(2) {
        opacity: 0.4;
        transform: translate(5px, -5px);
      }

      .icon:hover .layer span:nth-child(3) {
        opacity: 0.6;
        transform: translate(10px, -10px);
      }

      .icon:hover .layer span:nth-child(4) {
        opacity: 0.8;
        transform: translate(15px, -15px);
      }

      .icon:hover .layer span:nth-child(5) {
        opacity: 1;
        transform: translate(20px, -20px);
      }

      .layer span.fab {
        font-size: 25px;
        line-height: 55px;
        text-align: center;
        fill: #fff;
        background: #212121;
      }

      .layer span.fab1 {
        font-size: 30px;
        line-height: 55px;
        text-align: center;
        fill: #fff;
        background: #212121;
      }

      .user {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .name {
        font-size: 17px;
        font-weight: 700;
        color: #fff;
      }

      .username {
        font-size: 15px;
      }

      .details {
        display: flex;
        flex-direction: column;
        gap: 0;
        color: #fff;
        justify-content: space-around;
      }

      .about {
        color: #ccc;
        padding-top: 5px;
      }

      .card00 {
        width: 290px;
        height: 352px;
        background-image: linear-gradient(163deg, #506475 0%, black 100%);
        border-radius: 20px;
        transition: all .3s;
      }

      .card21 {
        width: 290px;
        height: 30px;
        background-color: #000000;
        border-radius: 20px;
        transition: all .2s;
      }

      .card2:hover {
        transform: scale(0.98);
        border-radius: 20px;
      }

      .card:hover {
        box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.30);
      }

    }
  }

  .text-sphere {
    .tagcloud {
      display: inline-block;
      margin-left: 28vw;
      margin-top: -1.8vw;
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      letter-spacing: 0.0625em;
      font-size: 1.3em;

      .tagcloud--item {
        color: #00ffffff;
        text-transform: uppercase;
      }

    }
  }

  .dark-hover-exp {

    h1 {
      text-align: center;
      font-size: 70px;
      font-family: 'Coolvetica';
      color: #d47331;
      font-weight: 400;
      margin-left: 90px;
    }

    .text-animate-hover {
      &:hover {
        color: #fff;
      }
    }

    h3 {
      padding-top: 0.25em;
    }

    .vertical-timeline-element-content {
      box-shadow: 0 0.25em 0.5em 0 rgba(38, 38, 38, 0.218),
        0 0.4em 1.25em 0 rgba(38, 38, 38, 0.118) !important;
      padding: 2em 3em !important;
      background-color: #181818 !important;
    }

    .vertical-timeline::before {
      background: #181818 !important;
    }

    .vertical-timeline-element-content-arrow {
      border-right: 7px solid #181818 !important;
    }

    .vertical-timeline-element-icon {
      box-shadow: 0 0 0 4px #181818, inset 0 2px 0 rgba(0, 0, 0, .08), 0 3px 0 4px rgba(0, 0, 0, .05) !important;
    }

    .date {
      color: rgb(201, 251, 255) !important;
      font-size: 18px !important;
      margin-top: 0px !important;
    }

    #description {
      margin: 1.5em 0 2em 0;
    }

    .button {
      text-decoration: none;
      padding: 0.5em 1em;
      border-radius: 5px;
      color: black !important;
    }

    .workButton {
      background-color: #06d6a0;
    }

    .workButton:hover {
      background-color: #0ac593;
    }

    .schoolButton {
      background-color: #f9c74f;
    }

    .schoolButton:hover {
      background-color: #f3bc3c;
    }
  }

  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div {
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px rgb(255, 239, 224);
    }

    .face1 {
      transform: translateZ(100px);
      color: #dd0031;
    }

    .face2 {
      transform: rotateY(90deg) translateZ(100px);
      color: #f06529;
    }

    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      color: #28a4d9;
    }

    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      color: #5ed4f4;
    }

    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      color: #efd81d;
    }

    .face6 {
      transform: rotateX(-90deg) translateZ(100px);
      color: #ec4d28;
    }
  }

  .dark-map {
    filter: invert(85%) hue-rotate(180deg);
  }

  .contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
      padding: 0;
      margin: 0;

      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
      }

      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    input[type='text'],
    input[type='email'] {
      width: 100%;
      border: 0;
      background: #323232;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
    }

    textarea {
      width: 100%;
      border: 0;
      background: #323232;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 20px;
      box-sizing: border-box;
      min-height: 150px;
    }

    .flat-button {
      color: #d47331;
      font-size: 11px;
      letter-spacing: 3px;
      text-decoration: none;
      padding: 8px 10px;
      border: 1px solid #d47331;
      float: left;
      border-radius: 4px;
      background: 0 0;
      text-transform: uppercase;
      float: right;
      text-align: center;
      margin-right: 10px;
    }
  }

  .info-map {
    position: absolute;
    background: #000;
    top: -20px;
    right: 32.11%;
    z-index: 999999;
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;

    span {
      font-size: 16px;

      span {
        color: #d47331;
      }
    }
  }

  .contact-page {

    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #d47331;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #d47331;
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #d47331;
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      font-size: 13px;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }

    .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 35%;
      vertical-align: middle;
      display: table-cell;
      max-height: 90%;
    }

    .text-animate-hover {
      &:hover {
        color: #fff;
      }
    }
  }

  .about-page {

    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #d47331;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #d47331;
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #d47331;
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      font-size: 13px;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }

    .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 35%;
      vertical-align: middle;
      display: table-cell;
      max-height: 90%;
    }

    .text-animate-hover {
      &:hover {
        color: #fff;
      }
    }
  }

  .home-page-dark {
    .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 40%;
      max-height: 90%;
    }

    h1 {
      color: #fff;
      font-size: 56px;
      line-height: 53px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #d47331;
        font-size: 18px;
        position: absolute;
        margin-top: -40px;
        left: -15px;
        opacity: 0.6;
      }

      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #d47331;
        font-size: 18px;
        position: absolute;
        margin-top: 18px;
        margin-left: 20px;
        animation: fadeIn 1s 1.7s backwards;
        opacity: 0.6;
      }

      img {
        opacity: 0;
        width: 85px;
        animation: rotateIn 1s linear both;
        animation-delay: 1.4s;
        margin-bottom: -8px;
        margin-right: -20px;
      }
    }

    h2 {
      color: #8d8d8d;
      margin-top: 20px;
      font-weight: 400;
      font-size: 11px;
      font-family: sans-serif;
      letter-spacing: 3px;
      animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
      color: #d47331;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #d47331;
      margin-top: 25px;
      float: left;
      animation: fadeInAnimation 1s 1.8s backwards;
      white-space: nowrap;

      &:hover {
        background: #d47331;
        color: #333;
      }
    }
  }

  .nav-bar-dark {
    background: #181818;
    width: 90px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo {
      display: block;
      padding: 1px 0;

      img {
        display: block;
        margin: 8px;
        width: 70px;
        height: auto;

        &.sub-logo {
          width: 50px;
        }
      }
    }

    nav {
      display: block;
      text-align: center;
      position: absolute;
      height: 310px;
      top: 30%;
      margin-top: -120px;
      width: 100%;

      a {
        font-size: 24px;
        color: #4d4d4e;
        display: block;
        line-height: 67px;
        height: 67px;
        position: relative;
        text-decoration: none;

        i {
          transition: all 0.3s ease-out;
        }

        &:hover {
          color: #fff;

          svg {
            opacity: 0;
          }

          &:after {
            opacity: 1;
          }
        }

        &:after {
          content: '';
          font-size: 9px;
          letter-spacing: 2px;
          position: absolute;
          bottom: 0;
          display: block;
          width: 100%;
          text-align: center;
          opacity: 0;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }

        &:first-child {
          &:after {
            content: 'HOME';
          }
        }
      }

      a.about-link {
        &:after {
          content: 'ABOUT';
        }
      }

      a.skill-link {
        &:after {
          content: 'SKILL';
        }
      }

      a.education-link {
        &:after {
          content: 'EDUCATION';
        }
      }

      a.experience-link {
        &:after {
          content: 'EXPERIENCE';
        }
      }

      a.projects-link {
        &:after {
          content: 'PROJECTS';
        }
      }

      a.resume-link {
        &:after {
          content: 'RESUME';
        }
      }

      a.contact-link {
        &:after {
          content: 'CONTACT';
        }
      }

      a.portfolio-link {
        &:after {
          content: 'PORTFOLIO';
        }
      }

      a.active {
        svg {
          color: #fff;
        }
      }
    }

    ul {
      position: absolute;
      bottom: 20px;
      width: 100%;
      display: block;
      padding: 0;
      list-style: none;
      text-align: center;
      margin: 0;

      li {
        a {
          padding: 7px 0;
          display: block;
          font-size: 18px;
          line-height: 16px;
          color: #4d4d4e;

          img {
            display: block;
            width: 22px;
            height: auto;
            margin: auto;

          }
        }

        &:hover {
          .anchor-icon {
            color: #fff;
          }
        }
      }
    }
  }
}

.light-theme {
  background: #dfdddd;
  color: #000000 !important;

  .Togglebutton2 {
    padding: 8px 18px !important;
    background: rgba(0, 0, 0, 0.393) !important;
    border-radius: 6px !important;
  }

  .Togglebutton {
    padding: 8px 18px !important;
    border-radius: 6px !important;

    &:hover {
      background: rgba(0, 0, 0, 0.215) !important;
    }
  }

  .Divider {
    background: black !important;
    width: 1.5px !important;
  }

  .mainsocialC {
    margin-top: -40px;
    z-index: 99999;
    display: flex;

    .img-custom {
      width: 55px;
      height: 55px;
      border: 1px solid #ff9100;
      border-radius: 10px;
      background: #fff;

      img {
        width: 100%;
      }
    }

    .icon-color-custom {
      color: black !important;
    }

    .RColor {
      -webkit-filter: invert(100%);
      filter: invert(100%);
    }

    .leetcode {
      width: 75%;
      margin-top: 6px;
    }

    .tooltip-container-custom {
      position: relative;
      cursor: pointer;
      transition: all 0.2s;
      font-size: 17px;
      border-radius: 25px;
      width: 35px;
      margin-left: 20px;
    }

    .tooltip-custom {
      margin-left: 40px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 5px;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s;
      border-radius: 15px;
      box-shadow: inset 5px 5px 5px #ff9100,
        inset -5px -5px 15px rgb(193, 193, 193, 0.1),
        5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
    }

    .profile-custom {
      background: rgb(163, 163, 163);
      border-radius: 10px 15px;
      padding: 10px;
      border: 1px solid #ff9100;
      width: max-content;
      z-index: 99999;
    }

    .tooltip-container-custom:hover .tooltip-custom {
      top: -150px;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }

    .icon-custom {
      text-decoration: none;
      color: #000;

      display: block;
      position: sticky;
    }

    .layer-custom {
      width: 55px;
      height: 55px;
      transition: transform 0.3s;
    }

    .icon-custom:hover .layer-custom {
      transform: rotate(-35deg) skew(20deg);
    }

    .layer-custom span {
      position: absolute;
      top: 0;
      left: 0;
      height: 65%;
      width: 35px;
      border: 1px solid #000;
      border-radius: 50px;
      transition: all 0.3s;
    }

    .layer-custom span,
    .text-custom {
      color: #000;
      border-color: #000;
    }

    .icon-custom:hover.layer-custom span {
      box-shadow: -1px 1px 3px #000;
    }

    .icon-custom .text-custom {
      position: absolute;
      left: 50%;
      bottom: -5px;
      opacity: 0;
      font-weight: 500;
      transform: translateX(-50%);
      transition: bottom 0.3s ease, opacity 0.3s ease;
    }

    .icon-custom:hover .text-custom {
      bottom: -20px;
      opacity: 1;
    }

    .icon-custom:hover .layer-custom span:nth-child(1) {
      opacity: 0.2;
    }

    .icon-custom:hover .layer-custom span:nth-child(2) {
      opacity: 0.4;
      transform: translate(5px, -5px);
    }

    .icon-custom:hover .layer-custom span:nth-child(3) {
      opacity: 0.6;
      transform: translate(10px, -10px);
    }

    .icon-custom:hover .layer-custom span:nth-child(4) {
      opacity: 0.8;
      transform: translate(15px, -15px);
    }

    .icon-custom:hover .layer-custom span:nth-child(5) {
      opacity: 1;
      transform: translate(20px, -20px);
    }

    .layer-custom span.fab {
      font-size: 18px;
      line-height: 40px;
      text-align: center;
      fill: #000;
      background: rgb(163, 163, 163);
    }

    .layer-custom span.fab1 {
      font-size: 18px;
      line-height: 40px;
      text-align: center;
      fill: #000;
      background: rgb(163, 163, 163);
    }

    .user-custom {
      display: flex;
      gap: 10px;
    }

    .name-custom {
      font-size: 17px;
      font-weight: 700;
      color: #000;
    }

    .details-custom {
      display: flex;
      flex-direction: column;
      gap: 0;
      color: #000;
    }

    .about-custom {
      color: #eee;
      padding-top: 5px;
    }

    .card00-custom {
      width: 290px;
      height: 352px;
      background-image: linear-gradient(163deg, #506475 0%, #ff9100 100%);
      border-radius: 20px;
      transition: all .3s;
    }

    .card21-custom {
      width: 290px;
      height: 30px;
      background-color: #000000;
      border-radius: 20px;
      transition: all .2s;
    }

    .card2-custom:hover {
      transform: scale(0.98);
      border-radius: 20px;
    }

    .card-custom:hover {
      box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.30);
    }
  }

  .main-social-2-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 500px;

    .main-social-2 {
      display: flex;
      justify-content: space-between;
      width: 500px;
      margin-left: -80px;

      .RColor {
        -webkit-filter: invert(0%);
        filter: invert(0%);
      }

      .img {
        width: 55px;
        height: max-content;
        border: none;
        border-radius: 10px;
        background: transparent;

        img {
          width: 100%;
        }
      }

      .light-icon {
        color: black !important;
      }

      .leetcode {
        width: 75%;
        margin-top: 6px;
      }

      .tooltip-container {
        position: relative;
        cursor: pointer;
        transition: all 0.2s;
        font-size: 17px;
        border-radius: 25px;
        width: 30px;
        margin-left: 20px;
      }

      .tooltip {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 5px;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s;
        border-radius: 15px;
        box-shadow: inset 5px 5px 5px rgb(108, 108, 108),
          inset -5px -5px 15px rgb(193, 193, 193, 0.1),
          5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
      }

      .profile {
        background: rgb(163, 163, 163);
        border-radius: 10px 15px;
        padding: 10px;
        border: 1px solid #616161;
        width: max-content;
        z-index: 99999;
      }

      .tooltip-container:hover .tooltip {
        top: -130px;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }

      .icon {
        text-decoration: none;
        color: #000;

        display: block;
        position: sticky;
      }

      .layer {
        width: 55px;
        height: 55px;
        transition: transform 0.3s;
      }

      .icon:hover .layer {
        transform: rotate(-35deg) skew(20deg);
      }

      .layer span {
        position: absolute;
        top: 0;
        left: 0;
        height: 90%;
        width: 50px;
        border: 1px solid #000;
        border-radius: 50px;
        transition: all 0.3s;
      }

      .layer span,
      .text {
        color: #000;
        border-color: #000;
      }

      .icon:hover.layer span {
        box-shadow: -1px 1px 3px #000;
      }

      .icon .text {
        position: absolute;
        left: 50%;
        bottom: -5px;
        opacity: 0;
        font-weight: 500;
        transform: translateX(-50%);
        transition: bottom 0.3s ease, opacity 0.3s ease;
      }

      .icon:hover .text {
        bottom: -35px;
        opacity: 1;
      }

      .icon:hover .layer span:nth-child(1) {
        opacity: 0.2;
      }

      .icon:hover .layer span:nth-child(2) {
        opacity: 0.4;
        transform: translate(5px, -5px);
      }

      .icon:hover .layer span:nth-child(3) {
        opacity: 0.6;
        transform: translate(10px, -10px);
      }

      .icon:hover .layer span:nth-child(4) {
        opacity: 0.8;
        transform: translate(15px, -15px);
      }

      .icon:hover .layer span:nth-child(5) {
        opacity: 1;
        transform: translate(20px, -20px);
      }

      .layer span.fab {
        font-size: 25px;
        line-height: 55px;
        text-align: center;
        fill: #000;
        background: rgb(163, 163, 163);
      }

      .layer span.fab1 {
        font-size: 30px;
        line-height: 55px;
        text-align: center;
        fill: #fff;
        background: rgb(163, 163, 163);
      }

      .user {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .name {
        font-size: 17px;
        font-weight: 700;
        color: #000;
      }

      .username {
        font-size: 15px;
      }

      .details {
        display: flex;
        flex-direction: column;
        gap: 0;
        color: #000;
        justify-content: space-around;
      }

      .about {
        color: #ddd;
        padding-top: 5px;
      }

      .card00 {
        width: 290px;
        height: 352px;
        background-image: linear-gradient(163deg, #506475 0%, black 100%);
        border-radius: 20px;
        transition: all .3s;
      }

      .card21 {
        width: 290px;
        height: 30px;
        background-color: #000000;
        border-radius: 20px;
        transition: all .2s;
      }

      .card2:hover {
        transform: scale(0.98);
        border-radius: 20px;
      }

      .card:hover {
        box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.30);
      }

    }

  }

  .text-sphere {
    .tagcloud {
      display: inline-block;
      margin-left: 28vw;
      margin-top: -1.8vw;
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      letter-spacing: 0.0625em;
      font-size: 1.3em;

      .tagcloud--item {
        color: rgb(0, 105, 105);
        text-transform: uppercase;
      }

    }
  }

  .dark-hover-exp {
    h1 {
      text-align: center;
      font-size: 70px;
      font-family: 'Coolvetica';
      color: #d47331;
      font-weight: 400;
      margin-left: 90px;
    }

    .text-animate-hover {
      &:hover {
        color: #000;
      }
    }

    h3 {
      padding-top: 0.25em;
    }

    .vertical-timeline-element-content {
      box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
        0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
      padding: 2em 3em !important;
      background-color: #a3a3a3 !important;
    }

    .vertical-timeline::before {
      background: #a3a3a3 !important;
    }

    .vertical-timeline-element-content-arrow {
      border-right: 7px solid #a3a3a3 !important;
    }

    .vertical-timeline-element-icon {
      box-shadow: 0 0 0 4px #a3a3a3, inset 0 2px 0 rgba(0, 0, 0, .08), 0 3px 0 4px rgba(0, 0, 0, .05) !important;
    }

    .date {
      color: rgb(74, 95, 97) !important;
      font-size: 18px !important;
      margin-top: 0px !important;
    }

    #description {
      margin: 1.5em 0 2em 0;
    }

    .button {
      text-decoration: none;
      padding: 0.5em 1em;
      border-radius: 5px;
      color: black !important;
    }

    .workButton {
      background-color: #06d6a0;
    }

    .workButton:hover {
      background-color: #0ac593;
    }

    .schoolButton {
      background-color: #f9c74f;
    }

    .schoolButton:hover {
      background-color: #f3bc3c;
    }
  }

  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);

    div {
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(90, 90, 90, 0.4);
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px #94887c;
    }

    .face1 {
      transform: translateZ(100px);
      color: #dd0031;
    }

    .face2 {
      transform: rotateY(90deg) translateZ(100px);
      color: #f06529;
    }

    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      color: #28a4d9;
    }

    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      color: #5ed4f4;
    }

    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      color: #efd81d;
    }

    .face6 {
      transform: rotateX(-90deg) translateZ(100px);
      color: #ec4d28;
    }
  }


  .dark-map-light {
    filter: invert(25%) hue-rotate(180deg);
  }

  .contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
      padding: 0;
      margin: 0;

      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
      }

      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    input[type='text'],
    input[type='email'] {
      width: 100%;
      border: 0;
      background: #c7c7c7;
      height: 50px;
      font-size: 16px;
      color: #565656;
      padding: 0 20px;
      box-sizing: border-box;
    }

    textarea {
      width: 100%;
      border: 0;
      background: #c7c7c7;
      height: 50px;
      font-size: 16px;
      color: #565656;
      padding: 20px;
      box-sizing: border-box;
      min-height: 150px;
    }

    .flat-button {
      color: #d47331;
      font-size: 11px;
      letter-spacing: 3px;
      text-decoration: none;
      padding: 8px 10px;
      border: 1px solid #d47331;
      float: left;
      border-radius: 4px;
      background: 0 0;
      text-transform: uppercase;
      float: right;
      text-align: center;
      margin-right: 10px;
    }
  }

  .info-map {
    position: absolute;
    background: #838282;
    top: -40px;
    right: 32.11%;
    z-index: 999999;
    width: 267px;
    padding: 20px;
    color: #000;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;

    span {
      font-size: 16px;

      span {
        color: #d47331;
      }
    }
  }

  .contact-page {

    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #d47331;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #d47331;
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #d47331;
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      font-size: 13px;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }

    .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 35%;
      vertical-align: middle;
      display: table-cell;
      max-height: 90%;
    }

    .text-animate-hover {
      &:hover {
        color: #000;
      }
    }
  }

  .about-page {

    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #d47331;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #d47331;
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #d47331;
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      font-size: 13px;
      font-family: sans-serif;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;

      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }

      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }

    .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 35%;
      vertical-align: middle;
      display: table-cell;
      max-height: 90%;
    }

    .text-animate-hover {
      &:hover {
        color: #000;
      }
    }
  }

  .home-page-dark {
    .text-zone {
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
      width: 40%;
      max-height: 90%;
    }

    h1 {
      color: #000000;
      font-size: 56px;
      line-height: 53px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #d47331;
        font-size: 18px;
        position: absolute;
        margin-top: -40px;
        left: -15px;
        opacity: 0.6;
      }

      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #d47331;
        font-size: 18px;
        position: absolute;
        margin-top: 18px;
        margin-left: 20px;
        animation: fadeIn 1s 1.7s backwards;
        opacity: 0.6;
      }

      img {
        opacity: 0;
        width: 85px;
        animation: rotateIn 1s linear both;
        animation-delay: 1.4s;
        margin-bottom: -8px;
        margin-right: -20px;
      }
    }

    h2 {
      color: #555555;
      margin-top: 20px;
      font-weight: 400;
      font-size: 11px;
      font-family: sans-serif;
      letter-spacing: 3px;
      animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
      color: #d47331;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 4px;
      font-family: sans-serif;
      text-decoration: none;
      padding: 10px 18px;
      border: 1px solid #d47331;
      margin-top: 25px;
      float: left;
      animation: fadeInAnimation 1s 1.8s backwards;
      white-space: nowrap;

      &:hover {
        background: #d47331;
        color: #333;
      }
    }
  }

  .nav-bar-dark {
    background: #a3a3a3;
    width: 90px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    min-height: 500px;

    .logo {
      display: block;
      padding: 1px 0;

      img {
        display: block;
        margin: 8px;
        width: 70px;
        height: auto;

        &.sub-logo {
          width: 50px;
        }
      }
    }

    nav {
      display: block;
      text-align: center;
      position: absolute;
      height: 310px;
      top: 30%;
      margin-top: -120px;
      width: 100%;

      a {
        font-size: 24px;
        color: #4d4d4e;
        display: block;
        line-height: 67px;
        height: 67px;
        position: relative;
        text-decoration: none;

        i {
          transition: all 0.3s ease-out;
        }

        &:hover {
          color: #000000;

          svg {
            opacity: 0;
          }

          &:after {
            opacity: 1;
          }
        }

        &:after {
          content: '';
          font-size: 9px;
          letter-spacing: 2px;
          position: absolute;
          bottom: 0;
          display: block;
          width: 100%;
          text-align: center;
          opacity: 0;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }

        &:first-child {
          &:after {
            content: 'HOME';
          }
        }
      }

      a.about-link {
        &:after {
          content: 'ABOUT';
        }
      }

      a.skill-link {
        &:after {
          content: 'SKILL';
        }
      }

      a.education-link {
        &:after {
          content: 'EDUCATION';
        }
      }

      a.experience-link {
        &:after {
          content: 'EXPERIENCE';
        }
      }

      a.projects-link {
        &:after {
          content: 'PROJECTS';
        }
      }

      a.resume-link {
        &:after {
          content: 'RESUME';
        }
      }

      a.contact-link {
        &:after {
          content: 'CONTACT';
        }
      }

      a.portfolio-link {
        &:after {
          content: 'PORTFOLIO';
        }
      }

      a.active {
        svg {
          color: #000000;
        }
      }
    }

    ul {
      position: absolute;
      bottom: 20px;
      width: 100%;
      display: block;
      padding: 0;
      list-style: none;
      text-align: center;
      margin: 0;

      li {
        a {
          padding: 7px 0;
          display: block;
          font-size: 18px;
          line-height: 16px;
          color: #c1c1c1;

          img {
            display: block;
            width: 22px;
            height: auto;
            margin: auto;

          }
        }

        &:hover {
          .anchor-icon {
            color: #000000;
          }
        }
      }
    }
  }
}

.dashboard {
  margin-top: 200px;
  margin-left: 200px;
}

@media screen and (max-width: 1200px) {
  body {
    overflow: visible;
  }
}