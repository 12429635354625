@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;600;700;800;900&display=swap');


.text-sphere {
    .tagcloud {
        display: inline-block;
        margin-left: 28vw;
        margin-top: -1.8vw;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        letter-spacing: 0.0625em;
        font-size: 1.3em;

        .tagcloud--item {
            color: #00ffffff;
            text-transform: uppercase;
        }

    }
}

.text-sphere-light {
    .tagcloud {
        display: inline-block;
        margin-left: 28vw;
        margin-top: -1.8vw;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        letter-spacing: 0.0625em;
        font-size: 1.3em;

        .tagcloud--item {
            color: rgb(0, 105, 105);
            text-transform: uppercase;
        }

    }
}

.tagcloud--item:hover {
    color: #ff6347;
}

.skills {
    position: relative;
    margin-left: 16vw;
}