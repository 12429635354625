.Resume-div {
    height: 580px;
    width: 500px;
    margin-top: -20px;
    margin-left: 500px;
    overflow: auto;
    margin-bottom: 1.5vw;
}

.Resume-div::-webkit-scrollbar {
    width: 0;
}

.resumeimg {
    width: 100%;
    position: relative;
}

.DownloadButton{
    margin-left: 49vw;
    padding: 8px 12px;
    border-radius: 5px;
    background-color: #999999;
    color: black;
    border: 2px;
    /* box-shadow: 0px 1px 2px 3px rgba(255, 255, 255, 0.3); */
    cursor: pointer;
    text-decoration: none;
    font-size: 15px;
}

.DownloadButton:hover{
    opacity: 0.7;
}

.DownloadButton:active{
    opacity: 0.5;
}